(function (window) {
  var strings = BatchGeoStrings.getStringsForComponent("BatchGeoForm");
  /**
   * BatchGeoForm is a component that simplifies form setup, validation, and
   * submission by trying to do as much automatic work as possible.
   *
   * Features:
   * - Give it a form and it will automatically pass back all the form fields.
   *   in an object for you for easy JSONing or serializing.
   * - Enable captcha with a simple boolean option.
   * - Automatic form error showing/hiding and submit prevention.
   * - Easy form validation for both sync and async validations.
   *
   * @example
   * new BatchGeoForm({
   *   form: $('.my-form'),
   *   enableCaptcha: true,
   *   fields: {
   *     password: {
   *       validation: function (value, fields) {
   *         return value.length > 6 || "Your password is not long enough"
   *       }
   *     }
   *     email: {
   *       // note: this passes in a done() param which makes this an async
   *       // validation and you need to call it to end the validation.
   *       validation: function (value, fields, done) {
   *         asyncCallExample('/validate-email', {email: value}, function (isEmailValid) {
   *           done(isEmailValid || "This email is invalid")
   *         })
   *       },
   *       eventHandlers: function () {
   *         change: function () { console.log('This field changed!') },
   *         blur: function () { console.log('This field was blurred!') }
   *       }
   *     },
   *   },
   *   handleSubmit: function (fields, captchaResponse) {
   *     $.ajax({
   *       url: 'api/login',
   *       data: _.merge(this.getFormAsData(), {g_recaptcha_response: captchaResponse}),
   *       dataType: 'json',
   *		 cache: false,
   *		 type: 'post',
   *       complete: function () {...}
   *     })
   *
   *     // note: return false, just like if you were doing a
   *     // form.on('submit') if you want to handle it without a legit form
   *     // submit. BatchGeoForm doesn't make an assumption of how you want to
   *     // submit the form.
   *     return false;
   *   }
   * })
   *
   *
   * @param {object} options An options hash to setup your form
   * @param {*} options.form The form element. This accepts anything jQuery
   * accepts. Under the hood it calls $(settings.form) so you can pass a
   * string, another jQuery object, DOM node, etc.
   * @param {BatchGeoForm~handleSubmit} options.handleSubmit A function to
   * call when the form is submitted AND when it passes validation (including
   * captcha if it is enabled). The value you return from this function is
   * passed to the submit event callback, meaning, if you return false it will
   * prevent the form from natually submitting (i.e. you would want to do this
   * for an ajax form). You will get the fields as the first param and the
   * catpcha response as the 2nd (if enabled).
   * @see {BatchGeoForm~handleSubmit} For param info for the callback.
   * @param {object} [options.fields] An object hash that defines fields. This
   * is not required to be filled out if you have name properties on all your
   * HTML inputs and you don't need any special validation outside of the HTML
   * based ones (like required, min, etc). If you need to customize such as
   * special validation, change the name of a field, or have it use a
   * different element customize this.
   * @param {BatchGeoForm~validator} [options.fields.validator] The validator
   * function that is called when the field is being validated.
   * @param {object} [options.fields.eventHandler] The eventHandler object
   * contains a dynamic dictionary inside, meaning, the properties you give it
   * are dynamically changed into event handlers. For example, in
   * eventHandlers you may have {blur: function () {...}} and that means it
   * will attach a blur handler on the field. You can pass any key/function
   * pair here and under the hood it uses jQuery and does on(keyName: func).
   * You can use this to set event listeners on the fields without needing to
   * manually do a $('.my-field').on('eventName', func). See the example code
   * above for usage example.
   * @param {boolean} options.enableCaptcha If true this will enable a captcha
   * on the form. The captcha will be required to be checked before submitting
   * the form. The response will also be passed back to you in the
   * settings.handleSubmit as the 2nd param.
   */
  var BatchGeoForm = function (options) {
    this.settings = $.extend(
      true,
      {
        form: null,
        fields: {},
        enableCaptcha: false,
        handleSubmit: function () {},
      },
      options,
    );

    this._createFields();
    this._attachListeners();

    if (this.settings.enableCaptcha) {
      this._enableCaptcha();
    }
  };

  /**
   * This callback is called when the form is submitted. It's part of the
   * options hash.
   * @callback BatchGeoForm~handleSubmit
   * @param {object[]} fields A collection (array of objects) of the fields.
   * @param {string|undefined} captchaResponse This will be a string from
   * Google's reCAPTCHA. You should never get null or an empty string as
   * handleSubmit should only ever be called if this exists. If you do not
   * enable captcha this will be undefined.
   * @see {@link BatchGeoForm} See the constructor for the full docs on this
   * option.
   * @see {@link BatchGeoForm.getFields} See getFields for example fields
   * output.
   */

  /**
   * The callback that is called when validations run against the field.
   * @callback BatchGeoForm~validator
   * @param {string} value The value of the field (same as calling .value).
   * @param {object[]} fields A collection (array of objects) of the fields.
   * Useful if you want to compare inputs such as a password validator where
   * you want to confirm they typed their password right in 2 fields.
   * @param {function} [done] If you want to do an async validation pass this
   * argument in. It will be a function and you call it when you are done.
   * You must pass in your pass/fail into this just like you would return it
   * for the sync BatchGeoForm~validator. for example:
   * done(isValid || "My error message")
   * @see {@link BatchGeoForm.getFields} See getFields for example fields
   * output.
   * @returns {boolean|string} Pass a boolean of true if the validator should
   * consider the input as valid. Pass a string if you want to show an error
   * message. For example if you reurn "This is invalid" that text will be
   * displayed under the input as an error.
   */

  /**
   * Returns all fields for the form in an object form. For example, a login
   * might get returned as this:
   *
   * @example
   * form.getFields();
   *
   * > {
   *   email: {
   *     name: 'email',
   *     value:'foo@bar.com',
   *     $element: $(...),
   *     errors: [...],
   *     validator: function () {...}
   *   },
   *   password: {
   *     name: 'password',
   *     value: '123456',
   *     $element: $(...),
   *     errors: [...],
   *     validator: function () {...}
   *   }
   * }
   *
   * @returns {object[]} Will return the fields object. Even if no fields
   * exist it will return an empty object.
   */
  BatchGeoForm.prototype.getFields = function () {
    return this._fields || {};
  };

  /**
   * Returns a specific field based on name.
   *
   * @example
   * form.getField('email');
   *
   * > {
   *   name: 'password',
   *   value: '123456',
   *   $element: $(...),
   *   errors: [...],
   *   validator: function () {...}
   * }
   *
   * @param {string} name The name attribute of the field you want to get.
   *
   * @returns {object} The field object
   *
   * @see {@link BatchGeoForm.getFields} for getting all fields
   */
  BatchGeoForm.prototype.getField = function (name) {
    if (this._fields) {
      return this._fields[name];
    }
  };

  /**
   * Set's a field's value based on the field name.
   *
   * Note this is NOT the field.value but literally the value of:
   * fields[yourFieldName] = value.
   *
   * @example
   * myBatchGeoForm.setField('password', {
   *   name: 'password',
   *   value: '123456',
   *   $element: $(...),
   *   errors: [...],
   *   validator: function () {...}
   * });
   *
   * @param {string} name The name atribute of the field you want to set.
   * @param {object} value The value you want to set on the field. Note that
   * this should be a field item not the field's "value" (so an object not a
   * string or number for example).
   *
   * @returns {object} Will return the value you pass in.
   */
  BatchGeoForm.prototype.setField = function (name, value) {
    if (!this._fields) this._fields = {};
    return (this._fields[name] = value);
  };

  /**
   * Returns an key/value pair object map of the form input names:value for
   * easy JSON.stringifying or form serialization.
   *
   * @example
   * {
   *   email: 'foo@bar.com',
   *   password: '123456'
   * }
   *
   * @returns {object} An object hash of all input name/value pairs.
   */
  BatchGeoForm.prototype.getFormAsData = function () {
    var fields = this.getFields();
    var dataObject = {};

    Object.keys(fields).forEach(function (fieldName) {
      return (dataObject[fieldName] = fields[fieldName].value);
    });

    return dataObject;
  };

  /**
   * This returns the recaptcha instance (a global called `grecaptcha`). This
   * is a helper method since it will check if grecaptcha exists or not before
   * returning. If grecaptcha is not loaded you wont get an error thrown about
   * it not being defined. You will simply get `undefined`.
   *
   * @returns {grecaptcha|undefined} If grecaptcha exists it will return that
   * but if not it will return undefined.
   * @see {@link https://developers.google.com/recaptcha/docs/v3|Google reCAPTCHA Docs}
   */
  BatchGeoForm.prototype.getCaptchaInstance = function () {
    return typeof grecaptcha !== "undefined" ? grecaptcha : undefined;
  };

  /**
   * Given the field name this function will validate the field and display
   * or hide errors as needed.
   *
   * @param {string} fieldName The name of the field to search for in the list
   * of fields. Under the hood it calls #getField(fieldName).
   * @param {boolean} onlyNegateErrors If true it will NOT show error messages
   * if it's not valid. It will only remove errors if it's valid. This is
   * useful in cases like keypress where maybe you want to check the length of
   * the input. On keypress 1 it may show an error saying it's too short.
   * Instead you may want to onlyNegateErrors:true here and leave it falsy for
   * field blurring instead so when they tab out it validates the input and
   * shows the error text.
   */
  BatchGeoForm.prototype.validateField = function (
    fieldName,
    onlyNegateErrors,
  ) {
    var self = this;
    var field = this.getField(fieldName);

    // If there's three args then it's an async validator
    if (field.validator.length === 3) {
      field.validator.call(
        this,
        field.value,
        this.getFields(),
        function (errorMessage) {
          self._handleFieldValidity(field, errorMessage, onlyNegateErrors);
        },
      );
    } else {
      var errorMessage = field.validator.call(
        this,
        field.value,
        this.getFields(),
      );
      self._handleFieldValidity(field, errorMessage, onlyNegateErrors);
    }
  };

  /**
   * This is the same as BatchGeo.validateField but calls it for every field.
   * It does NOT, however, allow you to pass a onlyNegateErrors flag. This is
   * most commonly used to validate all fields before you submit a form.
   *
   * @see {@link BatchGeoForm.validateField}
   */
  BatchGeoForm.prototype.validateFields = function () {
    var self = this;
    Object.keys(this.getFields()).forEach(function (fieldName) {
      self.validateField(fieldName);
    });
  };

  /**
   * Returns a boolean if all fields are valid and there's no errors.
   *
   * @returns {boolean} Returns true if all fields are valid
   */
  BatchGeoForm.prototype.isValid = function () {
    return !this.getInvalidFields().length;
  };

  /**
   * Returns a collection (array of objects) of invalid fields.
   *
   * @returns {object[]}
   * @see {@link BatchGeo.getFields} See getFields for example output.
   */
  BatchGeoForm.prototype.getInvalidFields = function () {
    var self = this;

    return Object.keys(this.getFields())
      .filter(function (fieldName) {
        return self.getField(fieldName).errors.length;
      })
      .map(function (fieldName) {
        return self.getField(fieldName);
      });
  };

  /**
   * This is a one-and-done method that once called will enable captcha. This
   * is a private method and generates a captcha element and then injects the
   * recaptcha API JS file.
   *
   * @private
   * @see {@link BatchGeoForm.getCaptchaInstance} to get the captcha instance
   * after this is enabled.
   */
  BatchGeoForm.prototype._enableCaptcha = function () {
    // Only run this once!
    if (this._captchaEnabled) return;

    this._captchaEnabled = true;

    $("<div />", {
      class: "g-recaptcha-container",
      html: $("<div />", {
        class: "g-recaptcha",
        "data-sitekey": "6Ldv5sUUAAAAAN4g67OnGKg4uRQ2GuTaZk9VV90x",
      }),
    }).insertBefore($(this.settings.form).find("[type=submit]"));

    $.getScript("https://www.google.com/recaptcha/api.js?");
  };

  /**
   * Creates a default field object. This includes generating, and inserting,
   * the error element that displays error text. It adds a validator function
   * as well which always returns true and an empty error array. It expects
   * you to pass in a name and $element into the options param.
   *
   * @param {object} options The options hash that is used to create the field
   * @param {string} options.name The field's name. This is usually the name
   * property of the element if it exists.
   * @param {jQuery} options.$element The jQuery selected element of the field
   * @param {boolean} options.validateOnKeyPress A boolean to set automatic validation on keypress on or off. Defaults to true
   * @param {boolean} options.validateOnBlur A boolean to set automatic validation on blur on or off. Defaults to true
   *
   * TODO: we should replace or do something if you call this twice on the
   * same field as it will append a new error element.
   *
   * TODO: This form should really use something like a BatchGeoInput
   * sub-component so its not just free form Objects.
   *
   * @private
   * @returns {object} Returns a default field object
   */
  BatchGeoForm.prototype._createDefaultField = function (options) {
    var self = this;
    var $errorElement = $("<span />", {
      class: "batchgeo-form-auto-error",
    });

    $errorElement.insertAfter(options.$element).hide();
    return {
      name: options.name,
      $element: options.$element,
      $errorElement: $errorElement,
      errors: [],
      eventHandlers: {},
      get value() {
        // Important: since radio elements have the same `name` field
        // when you ask for the value it refetches the elements matching
        // the current name and also makes sure to find the checked
        // version (which is the selected one)
        if (options.$element.attr("type") === "radio") {
          return $(self.settings.form)
            .find("[name=" + options.name + "]")
            .filter(":checked")
            .val();
        }
        // For all other elements just use the normal value. This should
        // work on all text based inputs as well as select fields.
        else {
          return options.$element.val();
        }
      },
      validateOnBlur: true,
      validateOnKeyPress: true,
      validator: function () {
        return true;
      },
    };
  };

  /**
   * Generates the form field data object. The object contains information
   * about field from metadata, to the validator, to the name. It tries to do
   * this automatically first by searching the form for elements with a name
   * property. Afterwards, it merges in user defined fields from the
   * settings.fields object.
   *
   * @private
   */
  BatchGeoForm.prototype._createFields = function () {
    var self = this;
    var userDefinedFields = Object.keys(this.settings.fields);

    // Take an initial go at creating all the fields automagically ✨🧙‍
    $(this.settings.form)
      .find("[name]")
      .each(function (index, element) {
        var $element = $(element);
        var name = $element.attr("name");

        self.setField(
          name,
          self._createDefaultField({
            name: name,
            $element: $element,
          }),
        );
      });

    // If we're overriding / merging any fields let's loop through them but
    // if not we can skip this.
    if (userDefinedFields.length > 0) {
      Object.keys(this.settings.fields).forEach(function (fieldName) {
        // If the field exists automatically get that. If not, such as
        // a totally made up field that's not in the DOM generate a
        // default field to work with.
        var defaultField =
          self.getField(fieldName) ||
          self._createDefaultField({
            name: fieldName,
            $element: $(null),
          });

        self.setField(
          fieldName,
          _.merge(
            defaultField,
            // Finally, override anything with this:
            self.settings.fields[fieldName],
          ),
        );
      });
    }

    // Applies all the events from the eventHandler object on the inputs
    var finalMergedFields = self.getFields();
    Object.keys(finalMergedFields).forEach(function (fieldName) {
      var field = finalMergedFields[fieldName];
      Object.keys(field.eventHandlers).forEach(function (eventName) {
        field.$element.on(eventName, field.eventHandlers[eventName]);
      });
    });
  };

  /**
   * Handles the field validity by adding/removing errors from the field's
   * errors array and also showing/hiding the error text elements. It has an
   * option (3rd param) called onlyNegateErrors which allows you to only hide
   * errors. This is useful in cases like keypress where maybe you want to
   * check the length of the input. On keypress 1 it may show an error saying
   * it's too short. Instead you may want to onlyNegateErrors:true here and
   * leave it falsy for field blurring instead so when they tab out it
   * validates the input and shows the error text.
   *
   * @param {object} field The field object to add/remove errors from and to
   * get the $errorElement off of to show/hide.
   * @param {boolean|string} errorMessage This will be a string if there's an
   * error message to display. If it is not a string it considers it valid. It
   * is best practice however to pass an explicit true so this is marked as a
   * boolean and string type.
   * @param {boolean} onlyNegateErrors If true it will NOT show error messages
   * if it's not valid. It will only remove errors if it's valid. This is
   * useful in cases like keypress where maybe you want to check the length of
   * the input. On keypress 1 it may show an error saying it's too short.
   * Instead you may want to onlyNegateErrors:true here and leave it falsy for
   * field blurring instead so when they tab out it validates the input and
   * shows the error text.
   *
   * @private
   */
  BatchGeoForm.prototype._handleFieldValidity = function (
    field,
    errorMessage,
    onlyNegateErrors,
  ) {
    if (typeof errorMessage !== "string") {
      field.errors = [];
      field.$errorElement.empty().hide();
    } else if (onlyNegateErrors !== true && typeof errorMessage === "string") {
      field.errors = [errorMessage];
      field.$errorElement.html(errorMessage).show();
    }
  };

  /**
   * Attaches all form listeners needed such as input blur validation, keyup
   * validation, and redirecting form submissions to
   * BatchGeoForm._handleSubmit.
   *
   * @private
   */
  BatchGeoForm.prototype._attachListeners = function () {
    var self = this;

    Object.keys(this.getFields()).forEach(function (fieldName) {
      var field = self.getField(fieldName);
      if (field.validateOnBlur) {
        field.$element.on("blur", function () {
          self.validateField(field.name);
        });
      }
      if (field.validateOnKeyPress) {
        field.$element.on(
          "keyup",
          _.debounce(function () {
            self.validateField(field.name, true);
          }, 250),
        );
      }
    });

    $(this.settings.form).on("submit", function () {
      return self._handleSubmit();
    });
  };

  /**
   * Handles submitting the form. It will make sure that if you have captcha
   * enabled that it's been selected. It will then validate all fields and
   * then finally, if everything is valid will call the `handleSubmit` option
   * for the user.
   *
   * @private
   * @see {@link BatchGeoForm} See the Constructor for the handleSubmit option
   */
  BatchGeoForm.prototype._handleSubmit = function () {
    var captchaResponse;
    if (
      this.settings.enableCaptcha &&
      !window.isLocal &&
      !this.getCaptchaInstance().getResponse()
    ) {
      BatchGeo.customAlert(strings.get("RECAPTCHA_UNCHECKED_ERROR"));
      return false;
    }
    if (this.settings.enableCaptcha) {
      captchaResponse = this.getCaptchaInstance().getResponse();
      grecaptcha.reset();
    }
    // Revalidate fields
    // TODO: This needs to be async!
    this.validateFields();

    // If the form is valid return whatever handleSubmit returns otherwise
    // if it's not valid return false
    return this.isValid()
      ? this.settings.handleSubmit.call(this, this.getFields(), captchaResponse)
      : false;
  };

  window.BatchGeoForm = BatchGeoForm;
})(window);
